import React from 'react';
import footerPhone from './../images/footer-phone.svg';
import footerMail from './../images/footer-mail.svg';



const Footer = () => {

  const redirectToHome = () => {
    if (sessionStorage.getItem("createNewAccountActive") === "ON") {      
      sessionStorage.removeItem("stg_mobile_number");
      window.location = "/newCustomer";
    } else {
      window.location = "/";
    }
  };

  return (
    <>
        <div className="row justify-content-center rm-row-margin footer-main">
          <div className="col-12 col-md-10">
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3">
                <div className="col-3 col-md-3 d-flex align-items-center">
                  <span className="mb-3 mb-md-0 text-white footer-font-left" onClick={ redirectToHome }>HT Readers</span>
                </div>

                <ul className="nav col-9 col-md-9 justify-content-end list-unstyled d-flex footer-font-right">
                  <li className="ms-3">
                    <a className="text-white" href="tel:+022-67764242">
                    <img src={footerPhone} width="12" height="12" /> Mumbai: 022-67764242
                    </a>
                  </li>
                  <li className="ms-3 ml-1">
                    <a className="text-white" href="tel:+011-60004242">
                    | Delhi: 011-60004242
                    </a>
                  </li>
                  <li className="ms-3 mr-3 ml-1">
                    <a className="text-white" href="tel:7009989177">
                    | Punjab: +91 7009989177
                    </a>
                  </li>
                  <li className="ms-3">
                    <a className="text-white" href="mailto:customerservice@hindustantimes.com">
                      <img src={footerMail} width="12" height="12" className='mr-1' />customerservice@hindustantimes.com
                    </a>
                  </li>
                </ul>
            </footer>
          </div>
        </div>
      </>
  );
};

export default Footer;
